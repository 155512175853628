jQuery(function ($) {
  "use strict";

  var GAEA = window.GAEA || {};

  /* ==================================================
	Contact Form Validations
================================================== */
  GAEA.ContactForm = function () {
    $(".contact-form").each(function () {
      var formInstance = $(this);
      formInstance.submit(function () {
        $("#message").slideUp(750, function () {
          $("#message").hide();

          $("#submit")
            .after('<img src="images/assets/ajax-loader.gif" class="loader" />')
            .attr("disabled", "disabled");
        });
        return false;
      });
    });
  };

  /* ==================================================
	Responsive Nav Menu
================================================== */
  GAEA.navMenu = function () {
    // Responsive Menu Events
    $("#menu-toggle").on("click", function () {
      $(this).toggleClass("opened");
      $(".main-navigation").slideToggle(500);
      return false;
    });
    $(window).on("resize", function () {
      var isLarge = window.innerWidth > 992;
      if (isLarge) {
        $("#menu-toggle").removeClass("opened");
        $(".main-navigation").css("display", "block");
      }
      if (!isLarge && $("#menu-toggle").hasClass("opened")) {
        $(".main-navigation").css("display", "block");
      } else {
        $("#menu-toggle").css("display", "none");
      }
    });
  };
  /* ==================================================
	Scroll to Top
================================================== */
  GAEA.scrollToTop = function () {
    var didScroll = false;

    var $arrow = $("#back-to-top");

    $arrow.on("click", function (e) {
      $("body,html").animate({ scrollTop: "0" }, 750, "easeOutExpo");
      e.preventDefault();
    });

    $(window).on("scroll", function () {
      didScroll = true;
    });

    setInterval(function () {
      if (didScroll) {
        didScroll = false;

        if ($(window).scrollTop() > 200) {
          $arrow.fadeIn();
        } else {
          $arrow.fadeOut();
        }
      }
    }, 250);
  };
  /* ==================================================
   SuperFish menu
================================================== */
  GAEA.SuperFish = function () {
    $(".sf-menu").superfish({
      delay: 200,
      animation: { opacity: "show", height: "show" },
      speed: "fast",
      cssArrows: false,
      disableHI: true,
    });
  };
  /* ==================================================
   Init Functions
================================================== */
  $(function () {
    GAEA.ContactForm();
    GAEA.scrollToTop();
    GAEA.navMenu();
    GAEA.SuperFish();
  });

  // Pages Design Functions

  // Any Button Scroll to section
  $(".scrollto").on("click", function () {
    $.scrollTo(this.hash, 800, { easing: "easeOutQuint" });
    return false;
  });

  // Centering the dropdown menus
  $(".main-navigation ul li").on("mouseover", function () {
    var the_width = $(this).find("a").width();
    var child_width = $(this).find("ul").width();
    var width = parseInt((child_width - the_width) / 2);
    $(this).find("ul").css("left", -width);
  });

  //Footer Widget Title
  $(".footer-widget .widgettitle").html(function (index, curHTML) {
    var text = curHTML.split(/[\s-]/),
      newtext = '<span class="accent-color">' + text.pop() + "</span>";
    return text.join(" ").concat(" " + newtext);
  });

  // Double border
  $(".double-border").each(function () {
    $(this).append(
      '<div class="accent-bg"></div><div class="accent-bg"></div>'
    );
  });

  // Heading Styles
  $(".title").each(function () {
    $(this).wrapInner("<div class='title-border'></div>");
  });
});
